import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../css/navbar.css';

export default function Navbar() {
    const [auth, setAuth] = useState("");
    const path = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(() => {
        const isAdmin = localStorage.getItem("ADMIN_NGO");
        const isUser = localStorage.getItem("NGO");
        if (isAdmin) {
            setAuth("ADMIN");
        } else if (isUser) {
            setAuth("USER");
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const topBar = document.querySelector('.top-bar');
        const navbar = document.querySelector('.navbar');

        function toggleStickyNavbar() {
            if (window.scrollY > topBar.offsetHeight) {
                navbar.classList.add('nav-sticky');
                topBar.classList.add('hide-top-bar');
            } else {
                navbar.classList.remove('nav-sticky');
                topBar.classList.remove('hide-top-bar');
            }
        }
        window.addEventListener('scroll', toggleStickyNavbar);
        return () => window.removeEventListener('scroll', toggleStickyNavbar);
    }, []);

    return (
        <>
            {/* Top Bar */}
            <div className="top-bar d-none d-md-block">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="top-bar-left">
                                <div className="text">
                                    <Link to="/admin">
                                        <i title="ADMIN" className="fa fa-solid fa-user-tie m-0" style={{ fontSize: "21px" }} />
                                    </Link>
                                </div>
                                <div className="text">
                                    <i className="fa fa-phone"></i>
                                    <a href="tel:+91-7738805577">
                                        <p>+91-7738805577</p>
                                    </a>
                                </div>
                                <div className="text">
                                    <i className="fa fa-envelope"></i>
                                    <a href="mailto:alqaimwelfareinfo@gmail.com">
                                        <p>alqaimwelfareinfo@gmail.com</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="top-bar-right">
                                <div className="social">
                                    <a href="https://www.facebook.com/profile.php?id=61567726026527&mibextid=ZbWKwL"><i className="fab fa-facebook-f"></i></a>
                                    <a href="https://www.instagram.com/al.qaim_welfare/profilecard/?igsh=OXpjZ3Y3bTExbnE3"><i className="fab fa-instagram"></i></a>
                                    <a href="https://www.threads.net/@al.qaim_welfare?invite=0"><i className="fab fa-threads"></i></a>
                                </div>
                                {auth.length > 0 && (
                                    <button
                                        onClick={() => {
                                            (auth === "USER" ? localStorage.removeItem("NGO") : localStorage.removeItem("ADMIN_NGO"));
                                            setAuth("");
                                            navigate("/");
                                        }}
                                        type="button"
                                        className="btn btn-outline-danger"
                                    >
                                        LOGOUT {auth}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Navbar */}
            <div className="navbar navbar-expand-lg bg-dark navbar-dark">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <h2>Al Qaim Welfare Foundation</h2>
                    </Link>
                    <div className="navbar-nav">
                        <div className="nav-home">
                            <Link to="/" className={`nav-item nav-link ${path === '/' ? "active" : ""}`}>Home</Link>
                        </div>
                        <div className="nav-about">
                            <Link to="/knowMore" className={`nav-item nav-link ${path === '/about' ? "active" : ""}`}>About</Link>
                        </div>
                        <div className="nav-sermons">
                            <Link to="/event" className={`nav-item nav-link ${path === '/event' ? "active" : ""}`}>Sermons</Link>
                        </div>
                        <div className="nav-donate">
                            <Link to="/donate" className="nav-item nav-link" style={{ color: 'green' }}>Donate Now</Link>
                        </div>
                        <div className="nav-contact">
                            <Link to="/contact" className={`nav-item nav-link ${path === '/contact' ? "active" : ""}`}>Contact</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
